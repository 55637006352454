import React, { PureComponent } from 'react';
import T from 'prop-types';
import { mayAlsoLikeShape } from 'arts/reducers/artist';
import SmallItemsCarousel, { WorkImage, calculateWith } from '../small_items_carousel';
import Classes from './styles';
import SimilarItems from '../similar_items';

const onMoreLikeThis = () => true;

const renderImage = ({
  // eslint-disable-next-line react/prop-types
  href, title, imgUrl, id, imageWidth, imageHeight
}, index) => {
  const width = calculateWith(imageWidth, imageHeight);
  return (
    <div key={ id } style={ { width } }>
      <WorkImage
        imageWidth={ imageWidth }
        imageHeight={ imageHeight }
        imgUrl={ imgUrl }
        href={ href }
        title={ title }
        onClick={ onMoreLikeThis }
        index={ index }
      />
      <SimilarItems
        id={ id }
        className={ Classes.more }
        eventSource="you may also like carousel"
      />
    </div>
  );
};

export default class MayAlsoLike extends PureComponent {
  static propTypes = {
    sourceId:        T.number,
    data:            mayAlsoLikeShape.isRequired,
    loadAlsoLike:    T.func.isRequired
  };

  componentDidMount() {
    const { sourceId, loadAlsoLike } = this.props;
    if (sourceId) {
      loadAlsoLike(sourceId);
    }
  }

  componentDidUpdate({ sourceId: sourceIdWas }) {
    const { sourceId, loadAlsoLike } = this.props;
    if (sourceId !== sourceIdWas) {
      loadAlsoLike(sourceId);
    }
  }

  render() {
    const { data } = this.props;
    return (
      <SmallItemsCarousel
        title="You May Also Like"
        gtmListName="recommended"
        items={ data || [] }
        renderItem={ renderImage }
        extended
      />
    );
  }
}
