import React, { PureComponent } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import {
  paperShape
} from 'arts/reducers/work';

import {
  selectionShape
} from 'arts/reducers/selection';

import Loader from '../Loader';
import Paper from './paper';
import Classes from './styles';
import AppClasses from '../Application/styles';

/* eslint-disable prefer-destructuring */
const findPaperAndFrame = (data, paperId, frameId) => {
  if (!data || data.length === 0) {
    return [null, null];
  }
  // // this.props.setFrame(nextProps.data[0], nextProps.data[0].options[0]);
  if (!paperId) {
    return [data[0], data[0].options[0]];
  }

  let paper = data.find((p) => p.paperId === paperId);
  if (!paper) {
    paper = data[0];
  }

  let frame = null;
  frame = paper.options.find((f) => f.frameId === frameId);
  if (!frame) {
    frame = paper.options[0];
  }

  return [paper, frame];
};
/* eslint-enable prefer-destructuring */

class PapersMenu extends PureComponent {
  static propTypes = {
    data:           T.arrayOf(paperShape),
    setFrame:       T.func.isRequired,
    setNonGlare:    T.func.isRequired,
    selection:      selectionShape.isRequired,
    resetSelection: T.func.isRequired,
    initialPaperId: T.number,
    initialFrameId: T.number,
    currentPrice:   T.number,
    currency:       T.string,
    landscape:      T.bool.isRequired,
    nonGlare:       T.bool,
    thumbnails:     T.array
  };

  static defaultProps = {
    data: [],
    initialPaperId: null,
    initialFrameId: null,
    currentPrice: null
  }

  constructor(props) {
    super(props);

    this.state = { headers: {} };
    this.renderPaper = this.renderPaper.bind(this);
    this.updateHeader = this.updateHeader.bind(this);
  }

  componentDidMount() {
    const [paper, frame] = findPaperAndFrame(
      this.props.data,
      this.props.initialPaperId,
      this.props.initialFrameId
    );
    this.props.setFrame(paper, frame);
  }

  componentDidUpdate(prevProps) {
    const {
      data, initialPaperId, initialFrameId, setFrame
    } = this.props;
    const { data: dataWas } = prevProps;
    if (dataWas.length === 0 && data.length > 0) {
      const [paper, frame] = findPaperAndFrame(
        data,
        initialPaperId,
        initialFrameId
      );
      setFrame(paper, frame);
    }
  }

  componentWillUnmount() {
    this.props.resetSelection();
  }

  updateHeader(paperId, header) {
    this.setState((state) => {
      const headers = {
        ...state.headers,
        [paperId]: header
      };

      return {
        ...state,
        headers
      };
    });
  }

  renderPaper(data) {
    const {
      landscape, setFrame, setNonGlare, currentPrice, selection, thumbnails, currency, nonGlare
    } = this.props;
    const { frame, paper } = selection || {};
    const { paperId } = paper || {};
    return (
      <Paper
        data={ data }
        key={ data.paperId }
        selectedFrame={ frame }
        thumbnails={ thumbnails }
        selectedPaperId={ paperId }
        setFrame={ setFrame }
        setNonGlare={ setNonGlare }
        nonGlare={ nonGlare }
        currentPrice={ currentPrice }
        currency={ currency }
        landscape={ landscape }
        updateHeader={ this.updateHeader }
      />
    );
  }

  renderHeaders() {
    if (!this.props.data || this.props.data.length === 0) {
      return (
        <>
          <div className={ classnames(Classes.header, AppClasses.skeleton) }>&nbsp;</div>
          <div className={ classnames(Classes.header, AppClasses.skeleton) }>&nbsp;</div>
          <div className={ classnames(Classes.header, AppClasses.skeleton) }>&nbsp;</div>
        </>
      );
    }
    const paperIds = this.props.data.map((v) => v.paperId);
    const res = [];
    paperIds.forEach((paperId) => {
      if (this.state.headers[paperId]) {
        res.push(this.state.headers[paperId]);
      }
    });
    return res;
  }

  render() {
    return (
      <>
        <div className={ Classes.heading }>select size:</div>
        { this.renderHeaders() }
        { this.props.data && this.props.data.length > 0 && this.props.data.map(this.renderPaper) }
      </>
    );
  }
}

export default PapersMenu;
