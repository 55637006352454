import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Classes from './styles';

const renderLink = (text, to, index, isLast) => (
  isLast ? (
    <Link to={ to } key={ index }>
      { text }
    </Link>
  ) : [
    (
      <Link to={ to } key={ index }>
        { text }
      </Link>
    ),
    (
      <span key="slash">/</span>
    )
  ]
);

export const BreadCrumbsSkeleton = () => (
  <div className={ Classes.root }>
    <div className={ Classes.links }>
      &nbsp;
    </div>
  </div>
);

const BreadCrumbs = ({
  links, className, rootClassName, children
}) => (
  <div className={ classnames(Classes.root, rootClassName) }>
    <div className={ classnames(Classes.links, className) }>
      {
        links.map(
          ({ text, to }, index) => renderLink(text, to, index, index === links.length - 1)
        )
      }
    </div>
    { children }
  </div>
);

BreadCrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    to: PropTypes.string.isRequired
  })).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  rootClassName: PropTypes.string
};

BreadCrumbs.defaultProps = {
  children: null,
  className: null,
  rootClassName: null
};

export default BreadCrumbs;
