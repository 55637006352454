/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect, useMemo, useState, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import queryString from 'query-string';
import AppClasses from '../Application/styles';
import Classes from './styles';
import BreadCrumbsGeneric from '../bread_crumbs';
import Preview from './preview';
import ToolBar from './toolbar';
import WallPicture from './wall_picture';
import Scroll from '../Application/scroll';
import Next from '../walls_as_page/next';
import { clearFilters } from '../walls';
import RearrangeModal from './rearrange_modal';
import GTMPageView from '../Application/data_layer';
import { AffirmPromo } from '../AddToCart/add_to_cart';
import { scheduleProductView } from '../../config/api';
import { onWallImpression, onWallWorkPictureClick, trackEvent } from '../../config/analytics';
import ShuffleButton from './shuffle_button';
import { wallPictureToPreview } from '../../config/lib';

const toggleValue = (originalValues, v) => {
  const values = { ...originalValues };
  if (values[v]) {
    delete values[v];
  } else {
    values[v] = true;
  }
  return values;
};

const WallSingle = ({
  id, description, name, loading, fetchWall, href, discountPercent,
  wallPictures, previewX, previewY, previewWidth, previewHeight, currentColor,
  imageUrl, background3DColor, backgroundColors, hangingImageUrl, hangingImageHref,
  skipBreadCrumbs, width, height, sharingToken, backgroundImageUrl,
  addWallToCart, addWallPicturesToCart,
  singleWall,
  isDarkBackground,
  currency
}) => {
  const [skipFrames, setSkipFrames] = useState({});
  const [skipWallPictures, setSkipWallPictures] = useState({});
  const [overriddenPictures, setOverriddenPictures] = useState({});
  const [shuffleMode, setShuffleModeAdv] = useState(false);

  const overrideWallPicture = (wallPictureId, alternative) => {
    setOverriddenPictures(
      s => ({ ...s, [wallPictureId]: alternative })
    );
  };

  const originalOrOverriden = (wp) => {
    const aCopy = JSON.parse(JSON.stringify(wp));
    const overriden = overriddenPictures[wp.wallPictureId];
    if (overriden) {
      const newWp = { ...aCopy, ...overriden };
      return newWp;
    }
    return wp;
  };

  const setShuffleMode = (mode) => {
    if (!mode) {
      setOverriddenPictures({});
    }
    trackEvent('wall shuffle', { mode: mode ? 'on' : 'off', 'wall id': id, 'wall name': name });
    setShuffleModeAdv(mode);
  };

  const onToggleSkipped = (v) => setSkipFrames(toggleValue(skipFrames, v));

  const onToggleSelected = (v) => setSkipWallPictures(toggleValue(skipWallPictures, v));

  const [rearrabgeVisible, setRearrabgeVisible] = useState(false);

  const toggleRearrangeBanner = (e) => {
    if (e) { e.preventDefault(); }
    setRearrabgeVisible(!rearrabgeVisible);
  };

  const allAvailable = () => Object.keys(skipWallPictures).length === 0
    && wallPictures.map(wp => wp.isAvailable).findIndex(v => v === false) === -1;

  const hasSkipFrames = () => Object.keys(skipFrames).length !== 0;

  const toggleSkipFrames = useCallback(() => {
    if (hasSkipFrames()) {
      setSkipFrames({});
      return;
    }
    const sf = {};
    wallPictures.forEach(wp => { sf[wp.wallPictureId] = true; });
    setSkipFrames(sf);
  }, [skipFrames, wallPictures]);

  useEffect(() => {
    if (!name && !loading && fetchWall) {
      fetchWall(id, sharingToken);
    }
  }, []);

  useEffect(() => {
    if (name && singleWall) {
      trackEvent('wall', {
        'wall id': id,
        'wall name': name
      });
      scheduleProductView(
        onWallImpression({
          id, name, wallPictures, discountPercent
        })
      );
    }
  }, [name]);

  const hanging = useMemo(() => (
    hangingImageUrl ? (
      <li className={ classnames(Classes.c2Text, Classes.hanging) }>
        <a href={ hangingImageHref }>
          <span>How To Hang This Wall</span>
          <img src={ hangingImageUrl } alt="How To Hang This Wall" />
          <span className={ Classes.view }>View Instructions</span>
        </a>
      </li>
    ) : null
  ), [hangingImageUrl]);

  const links = useMemo(() => [
    { text: 'Gallery Walls', to: '/walls' },
    { text: name, to: href }
  ], [id, name]);

  const discount = useMemo(() => (
    discountPercent ? (
      <>
        Purchase Entire Wall &amp; Save&nbsp;
        { parseInt(discountPercent, 10) }
        %
      </>
    ) : null
  ), [id, discountPercent]);

  if (!name) {
    return (
      <div className={ AppClasses.container20 }>
        <Loader active inline className={ Classes.loader }>
          Loading wall details, hold on for a sec...
        </Loader>
      </div>
    );
  }

  const selectedItems = () => wallPictures
    .filter((wp) => !skipWallPictures[wp.wallPictureId])
    .filter((wp) => wp.isAvailable);

  const calculatePrice = () => {
    const wholeWall = !hasSkipFrames() && allAvailable();
    const totalPrice = selectedItems()
      .map((wp) => (skipFrames[wp.wallPictureId] ? wp.prices.noFramesPrice : wp.prices.price))
      .reduce((sum, price) => sum + price, 0);
    const price = wholeWall ? totalPrice * (1 - (discountPercent / 100)) : totalPrice;
    return price;
  };

  const currentPrice = () => `${ currency }${ calculatePrice().toFixed(2) }`;

  const addToCart = () => {
    const overrides = {};
    Object.keys(overriddenPictures).forEach((k) => {
      const { workSizeId } = overriddenPictures[k];
      overrides[k] = workSizeId;
    });
    if (Object.keys(skipWallPictures).length || Object.keys(skipFrames).length) {
      const wps = {};
      wallPictures.forEach(wp => {
        if (!skipWallPictures[wp.wallPictureId]) {
          wps[wp.wallPictureId] = !skipFrames[wp.wallPictureId];
        }
      });
      addWallPicturesToCart(wps, id, overrides);
      return;
    }
    addWallToCart(id, overrides);
  };

  const previewProps = {
    wallPictures,
    previewX,
    previewY,
    previewWidth,
    previewHeight,
    currentColor,
    skipWallPictures,
    background3DColor,
    backgroundImageUrl,
    allowShuffle: true,
    isDarkBackground,
    shuffleMode,
    setShuffleMode,
    overrideWallPicture,
    wallId: id
  };

  const toolBarProps = {
    id,
    href,
    description,
    imageUrl,
    wallPictures,
    currentColor,
    width,
    height,
    noFavorites: !!sharingToken
  };

  const asTheyAppear = (withMobile) => (
    <>
      <div>
        <span>
          Items as they appear on the wall above &gt; total items:&nbsp;
          { selectedItems().length }
          &nbsp;=&nbsp;
          { currentPrice() }
        </span>
      </div>
      <button type="button" onClick={ addToCart } className={ classnames({ [Classes.mobileHidden]: withMobile }) }>
        Add All To Cart
      </button>
    </>
  );

  const onWallPictureClick = (position) => onWallWorkPictureClick(
    wallPictures[position], id, position
  );

  const wall = (
    <>
      <Scroll />
      { singleWall && id ? <GTMPageView pageType="product" section="gellery walls" key={ id } /> : null }
      <div className={ Classes.wall }>
        { !skipBreadCrumbs ? <BreadCrumbsGeneric links={ links } /> : null }
        <div className={ Classes.wallKeeper }>
          {/* mobile discount name name starts here */}
          <div className={ classnames(Classes.mobileVisible, Classes.mobileTitle) }>
            <div className={ Classes.discount }>
              { discount }
            </div>
            <h1>{ name }</h1>
            <ShuffleButton
              setShuffleMode={ setShuffleMode }
              shuffleMode={ shuffleMode }
              className={ Classes.mobileSwap }
            />
          </div>
          {/* mobile discount name name ends here */}
          <div className={ Classes.column1 }>
            {/* desktop version is here */}
            <Preview { ...previewProps } />
            <ToolBar { ...toolBarProps } />
          </div>
          <div className={ Classes.spacer } />
          <div className={ Classes.column2 }>
            <div className={ classnames(Classes.discount, Classes.mobileHidden) }>
              { discount }
            </div>
            <h1 className={ Classes.mobileHidden }>{ name }</h1>
            <p className={ classnames(Classes.description, Classes.mobileHidden) }>
              { description }
            </p>
            <Checkbox
              label="View unframed wall price"
              checked={ hasSkipFrames() }
              onChange={ toggleSkipFrames }
              className={ Classes.checkBox }
            />
            <div className={ Classes.buttonKeeper }>
              <button type="button" onClick={ addToCart }>
                ADD TO CART
                <span>
                  FOR&nbsp;
                  { currentPrice() }
                </span>
              </button>
              <div className={ Classes.affirm }>
                <AffirmPromo price={ calculatePrice() } currency={ currency } onWall />
              </div>
            </div>
            <p className={ classnames(Classes.description, Classes.mobileVisible) }>
              { description }
            </p>
          </div>
        </div>
        <div className={ classnames(Classes.wall, Classes.detais) } />
        <div className={ Classes.keeper }>
          <div>
            <div className={ Classes.columnHeader }>
              { asTheyAppear(true) }
            </div>
            <ul className={ classnames(Classes.column1, Classes.wallDetails) }>
              {
                wallPictures.map(
                  (wp, index) => (
                    <WallPicture
                      { ...originalOrOverriden(wallPictureToPreview(wp)) }
                      key={ wp.wallPictureId }
                      skipFrames={ skipFrames }
                      onToggleSkipped={ onToggleSkipped }
                      selected={ !skipWallPictures[wp.wallPictureId] }
                      onToggleSelected={ onToggleSelected }
                      index={ index }
                      onClick={ onWallPictureClick }
                    />
                  )
                )
              }
            </ul>
            <div className={ classnames(Classes.columnHeader, Classes.end) }>
              { asTheyAppear(false) }
            </div>
          </div>
          <div className={ Classes.spacer } />
          <div>
            <RearrangeModal visible={ rearrabgeVisible } onCancel={ toggleRearrangeBanner } />
            <a href="#" className={ Classes.raTutorialLink } onClick={ toggleRearrangeBanner }>
              <img src="https://assets.artfullywalls.com/assets/rearrange-promo/promo.jpg" alt="Gallery Wall" />
            </a>
            <div className={ classnames(Classes.columnHeader, Classes.full) }>
              <div>ADDITIONAL WALL INFORMATION</div>
            </div>
            <ul className={ classnames(Classes.column2, Classes.wallDetails) }>
              <li className={ classnames(Classes.c2Text, Classes.recommend) }>
                Recommended Paint Colors
                <div style={ { backgroundColor: ((backgroundColors || [])[0] || {}).rgb || '#fff' } } />
              </li>
              { hanging }
            </ul>
          </div>
        </div>
      </div>
    </>
  );

  if (skipBreadCrumbs) {
    return wall;
  }

  const params = clearFilters(queryString.parse(window.location.search));
  return (
    <div className={ Classes.carousel }>
      { wall }
      <a href={ `${ window.currentLocale.basename }/walls?${ queryString.stringify(params) }` } className={ Classes.next } aria-label="Next">
        <Next />
      </a>
    </div>
  );
};

WallSingle.propTypes = {
  fetchWall: PropTypes.func,
  id: PropTypes.number,
  currency: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  discountPercent: PropTypes.number,
  previewX: PropTypes.number,
  previewY: PropTypes.number,
  previewWidth: PropTypes.number,
  previewHeight: PropTypes.number,
  wallPictures: PropTypes.array,
  currentColor: PropTypes.string,
  imageUrl: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  backgroundColors: PropTypes.array,
  background3DColor: PropTypes.array,
  hangingImageUrl: PropTypes.string,
  hangingImageHref: PropTypes.string,
  skipBreadCrumbs: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  sharingToken: PropTypes.string,
  addWallToCart: PropTypes.func.isRequired,
  addWallPicturesToCart: PropTypes.func.isRequired,
  singleWall: PropTypes.bool,
  isDarkBackground: PropTypes.bool
};

WallSingle.defaultProps = {
  id: null,
  currency: null,
  fetchWall: null,
  name: null,
  description: null,
  loading: false,
  href: null,
  discountPercent: null,
  previewX: null,
  previewY: null,
  previewWidth: null,
  previewHeight: null,
  wallPictures: null,
  currentColor: null,
  imageUrl: null,
  backgroundImageUrl: null,
  backgroundColors: null,
  background3DColor: null,
  hangingImageUrl: null,
  hangingImageHref: null,
  skipBreadCrumbs: false,
  width: null,
  height: null,
  sharingToken: null,
  singleWall: false
};

export default WallSingle;
