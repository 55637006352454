import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { artistShape } from 'arts/reducers/artist';
import Delimiter from 'arts/components/Delimiter';
import SmallItemsCarousel from '../small_items_carousel';
import { renderText } from '../ArtistWorks/artist_details';

const onMoreOfArtist = () => true;

const MoreArtistWorks = ({
  artistId,
  skipId,
  artist,
  name,
  about,
  loadMoreWorks
}) => {
  useEffect(() => {
    if (artistId) { loadMoreWorks(artistId); }
  }, [artistId]);

  const { works } = useMemo(() => artist || {}, [artist]);
  const filteredWorks = useMemo(() => (works || []).filter(w => w.id !== skipId), [works, skipId]);
  const title = useMemo(() => (
    name ? (
      <>
        More of&nbsp;
        { name }
        &apos;s work
      </>
    ) : 'Loading artist details...'
  ), [name]);

  return (
    <div>
      <Delimiter />
      <div>
        <SmallItemsCarousel
          items={ filteredWorks }
          title={ title }
          onClick={ onMoreOfArtist }
          gtmListName="artist-works"
        />
        { renderText('About The Artist', about, false, true) }
      </div>
    </div>
  );
};

MoreArtistWorks.propTypes = {
  artistId:   PropTypes.number.isRequired,
  skipId:     PropTypes.number.isRequired,
  artist:     artistShape,
  name:       PropTypes.string.isRequired,
  about:      PropTypes.string,
  loadMoreWorks: PropTypes.func.isRequired
};

export default MoreArtistWorks;
