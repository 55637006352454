import React from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  paperShape,
  frameShape,
  workShape,
  thumbnailsShape
} from 'arts/reducers/work';

import AppClasses from '../Application/styles';
import Classes from './styles';
import Thumbnails from './thumbnails';
import SimilarItems from '../similar_items';
import Box from './box';
// import ImagePreview from './image_preview';
import ImagePreviewSwitcher from './image_preview_switcher';
import ShowToScale from './show_to_scale';
import ZoomIn from './zoom_in';

const Viewer = ({
  selectedFrame,
  selectedPaper,
  data,
  thumbnails,
  setOtherImage,
  setFramePreview,
  otherImageUrl,
  extraContent
}) => {
  const {
    previewWidth2,
    previewHeight2,
    zoomPreviewUrl: zoomPreviewUrlUsed,
    imageUrl: smallPreviewUrlUsed
  } = selectedFrame || {};

  const {
    id, hasWalls, skipMoreLikeThis, secondPreviewUrl
  } = data;

  const rollOver = (
    <div key="text" className={ Classes.rollOver }>
      {
        (id && zoomPreviewUrlUsed && !otherImageUrl) ? 'Roll Over to Zoom' : null
      }
      &nbsp;
    </div>
  );

  const similarItems = id && !skipMoreLikeThis ? (
    <SimilarItems
      id={ id }
      className={ Classes.moreLikeThis }
      eventSource="art"
    />
  ) : null;

  const artOnWall = id && hasWalls ? (
    <Link to={ `/walls?with_work_id=${ id }&as=thumb` } className={ Classes.thisArt }>
      <Box />
      <span>Walls With This Art</span>
    </Link>
  ) : null;

  return (
    <div className={ Classes.viewer }>
      <div className={ Classes.thumbsMenu }>
        <Thumbnails
          thumbnails={ thumbnails }
          setFramePreview={ setFramePreview }
          setOtherImage={ setOtherImage }
        />
        <ShowToScale
          smallPreviewUrl={ otherImageUrl ? null : smallPreviewUrlUsed }
          previewWidth2={ previewWidth2 }
          previewHeight2={ previewHeight2 }
        />
        { similarItems }
        { artOnWall }
      </div>
      <div className={ Classes.cKeeper }>
        <div
          className={
            classnames(
              Classes.carousel, {
                [AppClasses.skeleton]: !data?.imageUrl
              }
            )
          }
        >
          <ImagePreviewSwitcher
            selectedFrame={ selectedFrame }
            selectedPaper={ selectedPaper }
            data={ data }
            otherImageUrl={ otherImageUrl }
            secondPreviewUrl={ secondPreviewUrl }
          />
          <ZoomIn
            zoomPreviewUrl={ otherImageUrl || zoomPreviewUrlUsed }
            withFrame= { !!(selectedFrame && selectedFrame.frameId) }
          />
        </div>
        { rollOver }
        { extraContent }
      </div>
    </div>
  );
};

Viewer.propTypes = {
  id: T.number.isRequired,
  selectedFrame: frameShape,
  selectedPaper: paperShape,
  hasSelection: T.bool,
  data: workShape,
  thumbnails: thumbnailsShape,
  setOtherImage: T.func.isRequired,
  setFramePreview: T.func.isRequired,
  otherImageUrl: T.string,
  extraContent: T.node
};

Viewer.defaultProps = {
  otherImageUrl: null,
  data: {
    isHidden: false
  }
};

export default Viewer;
