import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MODAL_SELECTOR } from 'arts/config/constants';
import AddButton from './add_button';
import Modal from '../Modal';

import Classes from './styles';
import CheckoutStyles from '../checkout/styles';
import AppClasses from '../Application/styles';

const AddWithTotals = ({
  hasSelection,
  price,
  currency,
  frameId,
  workId,
  paperId,
  originalArtId,
  addArtToCart,
  addOriginalArtToCart,
  adding,
  added,
  nonGlare,
  allowQnty
}) => {
  const [qnty, setQnty] = useState(1);
  const [showWarning, setShowWarning] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);

  const limitQnty = (value, force = true) => {
    if (!value && !force) { return value; }
    if (value < 1) { return 1; }
    if (value > window.maxQnty) { return window.maxQnty; }
    return value;
  };

  const add = () => setQnty(prev => limitQnty((prev || 0) + 1, true));
  const remove = () => setQnty(prev => limitQnty((prev || 2) - 1, true));
  const hideWarning = () => setShowWarning(false);
  const hideEmpty = () => setShowEmpty(false);

  const onQntyChanged = (event) => setQnty(limitQnty(parseInt(event.target.value, 10)));

  const doAddToCart = () => {
    if (originalArtId) {
      addOriginalArtToCart({ originalArtId });
      return;
    }
    if (!qnty) {
      setShowEmpty(true);
      return;
    }
    if (qnty > 1) {
      setShowWarning(true);
      return;
    }
    addArtToCart({
      frameId, paperId, workId, qnty, nonGlare
    });
  };

  const hideWarningAndAddToCart = () => {
    hideWarning();
    addArtToCart({
      frameId, paperId, workId, qnty, nonGlare
    });
  };

  return (
    <>
      {
        showWarning ? (
          <Modal
            selector={ MODAL_SELECTOR }
            onCancel={ hideWarningAndAddToCart }
            className={ classnames(AppClasses.thanksForAction, Classes.warning) }
          >
            <p>
              Please note that you are adding multiple
              <br />
              versions of this item to your cart.
            </p>
            <div className={ Classes.buttonHolder }>
              <button
                type="button"
                className={ CheckoutStyles.applyButton }
                onClick={ hideWarningAndAddToCart }
              >
                OK
              </button>
            </div>
          </Modal>
        ) : null
      }
      {
        showEmpty ? (
          <Modal
            selector={ MODAL_SELECTOR }
            onCancel={ hideEmpty }
            className={ classnames(AppClasses.thanksForAction, Classes.warning) }
          >
            <p>
              Please select the number of items to add to your cart.
            </p>
            <div className={ Classes.buttonHolder }>
              <button
                type="button"
                className={ CheckoutStyles.applyButton }
                onClick={ hideEmpty }
              >
                OK
              </button>
            </div>
          </Modal>

        ) : null
      }
      <div className={ Classes.total }>
        {
          hasSelection && currency ? (
            <>
              Total:&nbsp;
              { currency }
              { Math.abs(price * (qnty || 1)).toFixed(2) }
            </>
          ) : ''
        }
      </div>
      <div className={ Classes.buttonContainer }>
        {
          allowQnty ? (
            <div className={ classnames(Classes.qnty, { [Classes.adding]: adding }) }>
              <button className={ Classes.qntyButton } type="button" onClick={ remove }>
                -
              </button>
              <input
                className={ Classes.qntyInput }
                type="text"
                value={ qnty || '' }
                onChange={ onQntyChanged }
              />
              <button className={ Classes.qntyButton } type="button" onClick={ add }>
                +
              </button>
            </div>
          ) : null
        }
        <AddButton
          doAddToCart={ doAddToCart }
          adding={ adding || !hasSelection || !currency }
          added={ added }
        />
      </div>
    </>
  );
};

AddWithTotals.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  frameId: PropTypes.number,
  workId: PropTypes.number,
  paperId: PropTypes.number,
  originalArtId: PropTypes.number,
  addArtToCart: PropTypes.func.isRequired,
  addOriginalArtToCart: PropTypes.func.isRequired,
  adding: PropTypes.bool.isRequired,
  added: PropTypes.bool.isRequired,
  allowQnty: PropTypes.bool.isRequired,
  nonGlare: PropTypes.bool,
  hasSelection: PropTypes.bool
};

export default AddWithTotals;
