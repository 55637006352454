import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import WallConstants from 'wb/config/const';
import Silhouette from 'wb/components/canvas/silhouette';
import { useComponentSize } from 'arts/config/use_component_size';
import Classes from './styles';
import WallPicturePreview from './wall_picture_preview';
import QuickShop from '../QuickShopModal/quick_shop';
import ShuffleButton from './shuffle_button';

// def wall_background_style(wall)
// res = "background:#{wall.preview_background_color}"
// if wall.background_image.blank ?
//   res << ";#{backround_3d(wall.preview_background_color)}"
//     else
//   res << " url(\"#{wall.background_image.remote_url}\"); background-size: cover"
// end
// res
// end

const Preview = ({
  wallPictures, previewX, previewY, previewWidth, previewHeight,
  currentColor, skipWallPictures, withSilhouette, className, background3DColor, backgroundImageUrl,
  allowShuffle, shuffleMode, setShuffleMode, overrideWallPicture,
  wallId, isDarkBackground
}) => {
  const [ref, rect] = useComponentSize();

  const [dpi, setDpi] = useState(0);

  useEffect(() => {
    if (rect.width) {
      setDpi(rect.width / previewWidth);
    }
  }, [rect && rect.width]);

  const renderPreview = dpi ? (
    wallPictures.map((wp, index) => (
      <WallPicturePreview
        isSkipped={ skipWallPictures[wp.wallPictureId] || !wp.isAvailable }
        dpi={ dpi }
        wp={ wp }
        previewX={ previewX }
        previewY={ previewY }
        index={ index }
        key={ wp.wallPictureId }
        showPlus={ !withSilhouette }
        shuffleMode={ shuffleMode }
        overrideWallPicture={ overrideWallPicture }
        wallId={ wallId }
      />
    ))
  ) : null;

  const sY = previewHeight === 138 ? (
    (previewHeight - WallConstants.silhouette.height + 43) / 2
  ) : (previewHeight - WallConstants.silhouette.height - 10) / 2;

  const silhouette = (dpi && withSilhouette) ? (
    <Silhouette
      dpi={ dpi }
      x={ dpi * ((previewWidth - WallConstants.silhouette.width) / 2) }
      y={ dpi * sY }
      silhouette="livingRoom"
    />
  ) : null;

  const style = {
    backgroundColor: currentColor
  };
  if (backgroundImageUrl) {
    style.backgroundImage = `url(${ backgroundImageUrl })`;
  } else if (background3DColor && background3DColor.length) {
    style.backgroundImage = `linear-gradient(135deg, ${ background3DColor[0] }, ${ background3DColor[1] })`;
  }

  return (
    <div
      ref={ ref }
      className={ classnames(Classes.preview, className) }
      style={ style }
    >
      { silhouette }
      { renderPreview }
      <QuickShop />
      {
        allowShuffle && (
          <ShuffleButton
            setShuffleMode={ setShuffleMode }
            shuffleMode={ shuffleMode }
            className={ Classes.mobileHidden }
            isDarkBackground={ isDarkBackground }
          />
        )
      }
    </div>
  );
};

Preview.propTypes = {
  className: PropTypes.string,
  previewX: PropTypes.number.isRequired,
  previewY: PropTypes.number.isRequired,
  previewWidth: PropTypes.number.isRequired,
  previewHeight: PropTypes.number.isRequired,
  wallPictures: PropTypes.array.isRequired,
  currentColor: PropTypes.string.isRequired,
  skipWallPictures: PropTypes.object.isRequired,
  withSilhouette: PropTypes.bool,
  background3DColor: PropTypes.array,
  backgroundImageUrl: PropTypes.string,
  allowShuffle: PropTypes.bool,
  setShuffleMode: PropTypes.func,
  shuffleMode: PropTypes.bool,
  overrideWallPicture: PropTypes.func,
  wallId: PropTypes.number.isRequired,
  isDarkBackground: PropTypes.bool
};

Preview.defaultProps = {
  withSilhouette: false,
  className: null,
  background3DColor: null,
  backgroundImageUrl: null
};

export default Preview;
