import React, { useEffect } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { frameShape, workShape } from 'arts/reducers/work';
import { toCamelCase } from 'arts/config/convertCase';
import { linkTo } from 'arts/config/lib';
import { trackEvent } from 'arts/config/analytics';
import MoreDetails from './more_details';
import PapersMenu from '../PapersMenu';
import Viewer from '../Viewer';
import WorkSummary from '../WorkSummary';
import AddToCart from '../AddToCart';
import SelectionDetails from '../selection_details';
import AdditionalInfo from '../additional_info';
import ExtraInfo from '../extra_info';
import BreadCrumbs from './bread_crumbs';
import AboutTheArt from './about_the_art';
import GTMPageView, { GtmDetails } from '../Application/data_layer';

import Classes from './styles';
import AppClasses from '../Application/styles';

const Work = ({
  id,
  detail,
  fetchWork,
  data,
  modal,
  paperId,
  frameId,
  selectedFrame,
  selectedPaper
}) => {
  useEffect(() => {
    const details = detail || (window.arts && toCamelCase(window.arts[id])) || {};
    fetchWork(id, details);
  }, [id]);

  let papers = null;
  const {
    title, authorName, authorId, authorSlug, description, aboutPaper,
    limitedEdition, isHidden, href,
    notAvailable, allowStores, storeItemId, aboutTheAuthor,
    workSizes, landscape
  } = data;

  useEffect(() => {
    if (id && title && authorId) {
      trackEvent('art page', {
        'work id': id,
        'author id': authorId,
        name: title,
        'author name': authorName,
        display: (modal ? 'modal' : 'page')
      });
    }
  }, [id, title, authorId]);

  if (notAvailable && !isHidden) {
    papers = (
      <div className={ Classes.notAvailable }>This art is no longer available</div>
    );
  } else {
    papers = (
      <PapersMenu
        data={ workSizes }
        initialPaperId={ paperId }
        initialFrameId={ frameId }
        landscape={ landscape }
      />
    );
  }

  const breadCrumbs = modal
    ? null
    : (
      <BreadCrumbs
        authorName={ data.authorName }
        authorSlug={ data.authorSlug }
        authorId={ parseInt(data.authorId, 10) }
      />
    );

  const {
    price: retailPrice, gtmVariant, frameId: selectedFrameId
  } = selectedFrame || {};

  const { paperId: selectedPaperId } = selectedPaper || {};

  const summaryProps = {
    title,
    authorName,
    authorId,
    authorSlug,
    description,
    printedOn: aboutPaper ? aboutPaper[0] : '',
    limitedEdition,
    isHidden,
    id,
    href,
    frameId: selectedFrameId,
    paperId: selectedPaperId
  };

  const aboutProps = { ...summaryProps, description };

  const addRemoveFromStore = allowStores && !notAvailable;

  let fullDetails = null;
  let moreDetails = {
    name:  authorName,
    about: aboutTheAuthor
  };

  if (authorId) {
    if (!modal && id) {
      moreDetails = {
        ...moreDetails,
        artistId: authorId,
        id
      };
    }
    fullDetails = modal ? (
      <Link to={ linkTo(href) } className={ Classes.fullDetails }>View Full Details</Link>
    ) : null;
  }

  const gtmDetailsData = {
    id, title, firstPaper: { retailPrice, gtmVariant }, authorName
  };

  return (
    <div className={ AppClasses.innerContainerArt }>
      { id ? <GTMPageView pageType="product" section="art prints" key={ id } /> : null }
      { retailPrice && title ? <GtmDetails key={ `d-${ id }-${ gtmVariant }` } data={ gtmDetailsData } /> : null }
      { breadCrumbs }
      <div className={ classnames(Classes.work, { [Classes.modalRoot]: modal }) }>
        <WorkSummary { ...summaryProps } isMobile />
        <Viewer id={ id } extraContent={ fullDetails } />
        <aside className={ Classes.sizeSelection }>
          <WorkSummary { ...summaryProps } />
          { papers }
          <AddToCart
            addRemoveFromStore={ addRemoveFromStore }
            storeItemId={ storeItemId }
            allowQnty
          />
          <AdditionalInfo />
          <AboutTheArt { ...aboutProps } />
          <SelectionDetails />
          <ExtraInfo />
        </aside>
      </div>
      <MoreDetails { ...moreDetails } />
    </div>
  );
};

Work.propTypes = {
  id: T.number.isRequired,
  detail: T.shape({}),
  fetchWork: T.func.isRequired,
  data: workShape,
  modal: T.bool,
  paperId: T.number,
  frameId: T.number,
  selectedFrame: frameShape,
  selectedPaper: T.shape({})
};

export default Work;
