import Constants from 'arts/config/constants';
import { getArtistWorks, getArtistInfo } from 'arts/config/api';

const fetchArtistWorksStart = (id) => ({
  type: Constants.ArtistWorks.FETCH,
  id
});

const fetchArtistWorksSuccess = (id, data) => ({
  type: Constants.ArtistWorks.FETCH_SUCCESS,
  id,
  data
});

const fetchArtistWorksFailure = (id, error) => ({
  type: Constants.ArtistWorks.FETCH_FAILURE,
  error,
  id
});

export const fetchArtistWorks = (id) => (dispatch, getState) => {
  const { artistWorks } = getState();
  const { collection } = artistWorks || {};
  if (collection && collection[0] && collection[0].authorId === id) {
    return;
  }

  dispatch(fetchArtistWorksStart(id));

  getArtistWorks(id)
    .then(
      (data) => dispatch(fetchArtistWorksSuccess(id, data)),
      (error) => dispatch(fetchArtistWorksFailure(id, error))
    );
};

const startFetchingArtistInfo = (id) => ({
  type: Constants.ArtistInfo.FETCH,
  id
});

const fetchArtistInfoSuccess = (id, data) => ({
  type: Constants.ArtistInfo.FETCH_SUCCESS,
  data,
  id
});

export const fetchArtistInfo = (artistId) => (dispatch, getState) => {
  dispatch(startFetchingArtistInfo(artistId));
  const { artists } = getState();
  const id = parseInt(artistId, 10);
  dispatch(fetchArtistWorks(id));
  const artist = artists.artists.find(a => a.id === id);
  if (artist) {
    dispatch(fetchArtistInfoSuccess(id, artist));
    return;
  }
  if (window.artists && window.artists[artistId]) {
    dispatch(fetchArtistInfoSuccess(id, window.artists[artistId]));
    return;
  }
  getArtistInfo(artistId)
    .then(
      (data) => dispatch(fetchArtistInfoSuccess(id, data))
    ).catch(() => {});
};
