/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import Classes from './styles';
import { WorkStoreItem } from '../store_item';
import AddWithTotals from './add_with_totals';
import ShoppableLookAssignment from '../shoppable_look_assignment';

export const currentPriceAndNonGlare = ({ frame, nonGlare }, priceKey) => ({
  [priceKey]: frame ? parseFloat((nonGlare ? frame.nonGlarePrice : frame.price), 10) : null,
  nonGlare: nonGlare || false
});

export const AffirmPromo = ({ price, currency, onWall }) => {
  if (window.minAffirmAmount) {
    useEffect(() => {
      if (window.affirm && window.affirm.ui && window.affirm.ui.ready) {
        window.affirm.ui.ready(() => {
          window.affirm.ui.refresh();
        });
      }
    }, [price, currency]);
  }

  if (!currency || !window.minAffirmAmount || price < window.minAffirmAmount) {
    return null;
  }

  return (
    <div className={ classnames(Classes.affirmPromo, { [Classes.onWall]: onWall }) }>
      <p className="affirm-as-low-as" data-page-type="payment" data-amount={ price * 100 } />
    </div>
  );
};

AffirmPromo.propTypes = {
  price: T.number.isRequired,
  onWall: T.bool,
  currency: T.string
};

const AddToCart = ({
  price, inStock, selection, frameId, workId,
  paperId, originalArtId, addRemoveFromStore, storeItemId,
  addArtToCart, addOriginalArtToCart,
  currency, adding, added,
  allowQnty, nonGlare
}) => (
  <>
    <div className={ Classes.barHolder }>
      <div className={ Classes.bar }>
        {
          inStock ? (
            <AddWithTotals
              hasSelection={ !!selection }
              price={ price }
              currency={ currency }
              frameId={ frameId }
              workId={ workId }
              paperId={ paperId }
              originalArtId={ originalArtId }
              addArtToCart={ addArtToCart }
              addOriginalArtToCart={ addOriginalArtToCart }
              adding={ adding }
              added={ added }
              allowQnty={ allowQnty }
              nonGlare={ nonGlare }
            />
          ) : (
            <div className={ Classes.soldOut }>
              Sold Out
            </div>
          )
        }
      </div>
    </div>
    { selection && inStock ? <AffirmPromo price={ price } currency={ currency } /> : null }
    {
      addRemoveFromStore ? (
        <div className={ Classes.storeItem }>
          <WorkStoreItem
            storeItemId={ storeItemId }
            workId={ workId }
            frameId={ frameId }
            paperId={ paperId }
          />
        </div>
      ) : null
    }
    { window.hasELinks ? (
      <ShoppableLookAssignment
        workId={ workId }
        frameId={ frameId }
        originalArtId={ originalArtId }
        paperId={ paperId }
      />
    ) : null }
  </>
);

AddToCart.propTypes = {
  price: T.number,
  currency: T.string,
  inStock: T.bool,
  selection: T.bool,
  frameId: T.number,
  workId: T.number,
  paperId: T.number,
  originalArtId: T.number,
  addRemoveFromStore: T.bool,
  storeItemId: T.number,
  addArtToCart: T.func.isRequired,
  addOriginalArtToCart: T.func.isRequired,
  adding: T.bool.isRequired,
  added: T.bool.isRequired,
  allowQnty: T.bool.isRequired,
  nonGlare: T.bool
};

AddToCart.defaultProps = {
  price: null,
  inStock: true,
  selection: false,
  frameId: null,
  workId: null,
  paperId: null,
  originalArtId: null,
  addRemoveFromStore: false,
  storeItemId: null
};

export default AddToCart;
