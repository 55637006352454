import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { linkTo } from 'arts/config/lib';
import { aliceSliderSettins, handleDragStart } from 'arts/components/slider_arrows';
import Classes from './styles';
import { onWorkItems, onWorkClick } from '../../config/analytics';
import { renderText } from '../ArtistWorks/artist_details';

// see imageheight, imagepadding in styles
const { imagepadding, imageheight } = Classes;

export const calculateWith = (imageWidth, imageHeight) => (
  imageWidth * (parseInt(imageheight, 10) / imageHeight) + 2 * parseInt(imagepadding, 10)
);

export const WorkImage = ({
  imgUrl,
  imageHeight,
  imageWidth,
  title,
  href,
  onClick,
  index
}) => {
  const width = calculateWith(imageWidth, imageHeight);
  const style = { width };
  const onItemClick = () => onClick(index);
  return (
    <Link
      to={ linkTo(href) }
      className={ Classes.item }
      style={ style }
      onDragStart={ handleDragStart }
      onDragEnd={ handleDragStart }
      onClick={ onItemClick }
    >
      <img src={ imgUrl } alt={ title } style={ style } />
    </Link>
  );
};

WorkImage.propTypes = {
  imgUrl: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  href: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired
};

const SmallItemsCarousel = ({
  items, title, onClick, renderItem, gtmListName, extended
}) => {
  const ref = useRef(null);

  const className = classnames(Classes.images, { [Classes.extended]: extended });

  if (!renderItem && (!items || !items.length)) {
    return renderText(title, <div className={ className } ref={ ref } />, true);
  }

  const onItemClick = (position) => {
    onWorkClick(items[position], 'product', gtmListName, position);
    if (onClick) { onClick(); }
  };

  const content = renderItem ? items.map(renderItem) : items.map((i, index) => (
    <WorkImage { ...i } onClick={ onItemClick } key={ index } index={ index } />
  ));

  const findVisible = () => {
    const visibleIndexes = [];
    if (ref.current) {
      const parent = ref.current.getElementsByClassName('alice-carousel')[0];
      if (!parent) { return []; }
      const { left: parentLeft, right: parentRight } = parent.getBoundingClientRect();
      const elements = ref.current.getElementsByClassName('alice-carousel__stage-item');
      for (let i = 0, l = elements.length; i < l; i += 1) {
        const { left, right } = elements[i].getBoundingClientRect();
        const isVisible = left >= parentLeft && right <= parentRight;
        if (isVisible) {
          visibleIndexes.push(i);
        }
      }
    }
    return visibleIndexes;
  };

  const reportVisible = () => {
    const works = findVisible().map(i => items[i]);
    if (works.length) {
      onWorkItems({ items: works, listName: gtmListName, pageType: 'product' });
    }
  };

  const images = (
    <div className={ className } ref={ ref }>
      <AliceCarousel
        { ...aliceSliderSettins() }
        items={ content }
        onInitialized={ reportVisible }
        onSlideChanged={ reportVisible }
      />
    </div>
  );

  return renderText(title, images, true);
};

SmallItemsCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    imgUrl: PropTypes.string,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    href: PropTypes.string,
    title: PropTypes.string
  })),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  renderItem: PropTypes.func,
  gtmListName: PropTypes.string,
  extended: PropTypes.bool
};

export default SmallItemsCarousel;
