import React from 'react';
import T from 'prop-types';
import classnames from 'classnames';

import Zoom from './zoom';
import Classes from './styles';

import { FLOAT_DECKLED, FLOAT_STRIGHT } from '../PapersMenu/mounts';

const PaperView = ({
  width, height, borderH, borderW, backgroundImage, unframed,
  frameThicknessH, frameThicknessV, zoomPreviewUrl, imageUrl, title,
  mount, isTapestry
}) => {
  const styles = { flexBasis: width, height };
  if (backgroundImage) {
    styles.backgroundImage = `url(${ backgroundImage })`;
  }
  const innerStyle = {
    paddingTop: `${ borderH }%`,
    paddingBottom: `${ borderH }%`,
    paddingLeft: `${ borderW }%`,
    paddingRight: `${ borderW }%`
  };

  const paperClass = unframed ? Classes.externalShadow : '';
  const frameClass = backgroundImage ? Classes.externalShadow : '';

  let frameShadow = null;
  const isFloat = FLOAT_DECKLED === mount || FLOAT_STRIGHT === mount;
  if (frameThicknessH && !isFloat && !isTapestry) {
    const frameShadowPos = {
      top: `${ frameThicknessV }%`,
      right: `${ frameThicknessH }%`,
      bottom: `${ frameThicknessV }%`,
      left: `${ frameThicknessH }%`
    };
    frameShadow = <div className={ Classes.frameShadow } style={ frameShadowPos } />;
  }

  return (
    <Zoom
      style={ styles }
      className={ classnames(Classes.paper, frameClass) }
      zoomPreviewUrl={ zoomPreviewUrl }
    >
      <div style={ innerStyle } className={ paperClass }>
        { frameShadow }
        <img src={ imageUrl } alt={ title } width="100%" height="100%" />
      </div>
    </Zoom>
  );
};

PaperView.propTypes = {
  width: T.string,
  height: T.string,
  borderH: T.number,
  borderW: T.number,
  imageUrl: T.string,
  backgroundImage: T.string,
  zoomPreviewUrl: T.string,
  title: T.string,
  landscape: T.bool,
  unframed: T.bool.isRequired,
  frameThicknessV: T.number,
  frameThicknessH: T.number,
  mount: T.string,
  isTapestry: T.bool
};

PaperView.defaultProps = {
  frameThicknessV: null,
  frameThicknessH: null,
  mount: null,
  isTapestry: false
};

export default  PaperView;
