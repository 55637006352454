/* eslint-disable prefer-destructuring */
import React, {
  useState, useEffect, useRef, useCallback, useMemo
} from 'react';
import T from 'prop-types';
import DefaultContainer from './container';

const InfiniteScroll = ({
  items,
  itemRenderer,
  itemsContainer = DefaultContainer,
  onLoad,
  rootClassName,
  threshold = 100
}) => {
  const rootRef = useRef(null);
  const [ticking, setTicking] = useState(false);

  const recalculate = useCallback(() => {
    if (!rootRef.current) {
      return;
    }
    const { top, height } = rootRef.current.getBoundingClientRect();
    const scrollY = window.scrollY || window.pageYOffset;
    const innerHeight = window.innerHeight;
    const rootElBottom = top + scrollY + height;
    const viewportBottom = scrollY + innerHeight;

    const shouldLoad = rootElBottom - threshold <= viewportBottom;

    if (shouldLoad) {
      setTicking(true);
    }
  }, [onLoad, threshold]);

  const onChange = useCallback(() => {
    if (!ticking) {
      setTimeout(() => {
        window.requestAnimationFrame(recalculate);
      }, 0);
    }
  }, [ticking, recalculate]);

  useEffect(() => {
    window.addEventListener('resize', onChange);
    window.addEventListener('scroll', onChange);

    recalculate();

    return () => {
      window.removeEventListener('resize', onChange);
      window.removeEventListener('scroll', onChange);
    };
  }, [onChange, recalculate]);

  useEffect(() => {
    if (ticking && items.length) {
      setTimeout(() => { setTicking(false); }, 0);
    }
  }, [items && items.length, ticking]);

  useEffect(() => {
    if (ticking) {
      onLoad();
    }
  }, [ticking, onLoad]);

  const content = useMemo(() => (
    React.createElement(
      itemsContainer,
      null,
      items.map(itemRenderer)
    )
  ), [items, itemRenderer, itemsContainer]);

  return (
    <div className={ rootClassName } ref={ rootRef }>
      { content }
    </div>
  );
};

InfiniteScroll.propTypes = {
  items: T.instanceOf(Array).isRequired,
  itemRenderer: T.func.isRequired,
  itemsContainer: T.oneOfType([T.element, T.func]),
  onLoad: T.func.isRequired,
  rootClassName: T.string,
  threshold: T.number
};

InfiniteScroll.defaultProps = {
  rootClassName: undefined,
  threshold: 100,
  itemsContainer: DefaultContainer
};

export default InfiniteScroll;
