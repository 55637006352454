import { connect } from 'react-redux';
import AdditionalInfo from './additional_info';

const mapStateToProps = ({ selection, work }) => ({
  withFrame: !!(selection.frame || {}).frameId,
  paperMaterial: (
    work.data
    && work.data.workSizes
    && work.data.workSizes[0]
    && work.data.workSizes[0].paperMaterial
  ),
  aboutPaper: work.data && work.data.aboutPaper
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
